import createEditorControl from "@/components/editor/controls/createEditorControl";
import { Quote } from "@/components/icons";

export const BlockquoteControl = createEditorControl({
  name: "blockquote",
  label: "Citation",
  shortcut: ["⌘", "m"],
  icon: <Quote />,
  command: ({ editor }) => editor.chain().focus().toggleBlockquote().run(),
  canExecute: ({ editor }) => editor.can().setBlockquote(),
});
