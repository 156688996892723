import { RichTextKit } from "@sirius/editor-model";
import { Dropcursor } from "@tiptap/extension-dropcursor";
import deepEqual from "deep-equal";
import { ComponentPropsWithoutRef, FC, FocusEvent, memo, useRef } from "react";
import { useField } from "react-final-form";
import { Editor, EditorProvider } from "swash/editor";
import {
  Toolbar,
  ToolbarItem,
  ToolbarSeparator,
} from "swash/editor/components/Toolbar";
import { cn } from "swash/utils/classNames";
import { useLiveRef } from "swash/utils/useLiveRef";

import { BlockTemplatesControl } from "@/components/editor/controls/BlockTemplates";
import { BlockquoteControl } from "@/components/editor/controls/Blockquote";
import { BoldControl } from "@/components/editor/controls/Bold";
import { BulletListControl } from "@/components/editor/controls/BulletList";
import { HeadingControl } from "@/components/editor/controls/Heading";
import { ItalicControl } from "@/components/editor/controls/Italic";
import { LinkControl } from "@/components/editor/controls/Link";
import { OrderedListControl } from "@/components/editor/controls/OrderedList";
import { RedoControl } from "@/components/editor/controls/Redo";
import { RemoveStyleControl } from "@/components/editor/controls/RemoveStyle";
import { StrikethroughControl } from "@/components/editor/controls/Strike";
import { SubscriptControl } from "@/components/editor/controls/Subscript";
import { SuperscriptControl } from "@/components/editor/controls/Superscript";
import { ToggleCaseControl } from "@/components/editor/controls/ToggleCase";
import { UnderlineControl } from "@/components/editor/controls/Underline";
import { UndoControl } from "@/components/editor/controls/Undo";
import { AddLineButton } from "@/components/editor/extensions/add-line";
import { DragHandle } from "@/components/editor/extensions/drag";
import { renderEmojiSuggestionList } from "@/components/editor/extensions/emoji";
import { LinkEditorMenu } from "@/components/editor/extensions/link-menu";
import { MerciAppSpellCheckControl } from "@/components/editor/extensions/merciapp";
import { ProlexisSpellCheckControl } from "@/components/editor/extensions/prolexis";
import {
  FrequentlyUsedControl,
  SpecialCharControl,
} from "@/components/editor/extensions/special-characters";
import {
  convertContentToRichTextNode,
  convertRichTextNodeToContent,
} from "@/components/editor/utils/converter";
import { FieldControl } from "@/components/fields/FieldControl";
import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { useFieldState } from "@/components/fields/FieldState";
import { Omega, SpellCheck } from "@/components/icons";
import { RichEditor } from "@/components/rich-editor/RichEditor";
import { useRichEditorState } from "@/components/rich-editor/RichEditorState";
import { RichEditorToolbar } from "@/components/rich-editor/RichEditorToolbar";
import { fromNodes } from "@/components/rich-editor/convert/fromNodes";
import { toNodes } from "@/components/rich-editor/convert/toNodes";
import { SpellCheckProvider } from "@/components/rich-editor/plugins/spell-check-control/SpellCheckPluginContext";
import { useEnhancedState } from "@/components/rich-editor/utils/useEnhancedState";
import { useBlockTemplates } from "@/containers/BlockTemplates";
import { RichEditor as RichEditorContent } from "@/containers/RichEditor";
import { useHasExperimentalFeature } from "@/containers/User";
import {
  useHasPlugin,
  useInitialEditorOptions,
} from "@/containers/editor/EditorOptions";
import { useRichEditorPreset } from "@/containers/editor/presets/preset-rich-editor";

const toContentState = (value: any, plugins: any) =>
  fromNodes(value || [], plugins);

export const DetachedEditor: FC<InnerEditorProps> = (props) => (
  <SpellCheckProvider>
    <InnerEditor {...props} />
  </SpellCheckProvider>
);

const InnerEditor: FC<InnerEditorProps> = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  disabled,
  readOnly,
  ...props
}) => {
  const blockTemplates = useBlockTemplates();
  const plugins = useRichEditorPreset({
    blocks: false,
    headerThree: false,
  });
  const editingRef = useRef(false);
  const onChangeRef = useLiveRef(onChange);

  const [contentState, setContentState] = useEnhancedState(
    () => toContentState(value, plugins),
    (nextState: any) => {
      if (onChangeRef.current) {
        onChangeRef.current(toNodes(nextState));
      }
    },
  );

  const editor = useRichEditorState({
    contentState,
    setContentState,
    plugins,
    blockTemplates,
    readOnly: readOnly || disabled,
  });

  const handleFocus = (evt: FocusEvent<HTMLDivElement>) => {
    if (onFocus) onFocus(evt);
    if (editingRef.current) return;
    editingRef.current = true;
  };

  const handleBlur = (evt: FocusEvent<HTMLDivElement>) => {
    if (onBlur) onBlur(evt);
    editingRef.current = false;
  };

  return (
    <div {...props}>
      <div className="m-4">
        <RichEditorToolbar
          {...editor}
          // @ts-expect-error js is not typed
          onMouseDown={(event: MouseEvent<HTMLDivElement>) => {
            event.preventDefault();
            editor.lockFocus();
          }}
        />
      </div>
      <RichEditor
        {...editor}
        // @ts-expect-error js is not typed
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </div>
  );
};

interface InnerEditorProps extends ComponentPropsWithoutRef<"div"> {
  name: string;
  label?: string;
  value?: any;
  onChange?: (value: any) => void;
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
  onFocus?: (event: FocusEvent<HTMLDivElement>) => void;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

export function useRichTextEditorField(
  name: string,
  {
    required = false,
    id,
    orientation = "vertical",
    format,
    ...options
  }: UseRichTextEditorFieldOptions = {},
) {
  const field = useField(name, {
    format,
    ...options,
  });
  return useFieldState({
    field,
    id,
    orientation,
    required,
  });
}

interface UseRichTextEditorFieldOptions {
  required?: boolean;
  id?: string;
  orientation?: "vertical" | "horizontal";
  format?: any;
  [key: string]: any;
}

const RichTextEditor = memo(function RichTextEditor({
  value,
  placeholder = "Contenu...",
  disabled,
  invalid,
  onChange,
  ...props
}: RichTextEditorProps) {
  const {
    typographicalCorrection,
    italicsBetweenQuotes,
    emojis,
    blockTemplates,
  } = useInitialEditorOptions();

  const hasProlexis = useHasPlugin("prolexis");
  const hasMerciApp = useHasPlugin("merciapp");
  const hasBlockTemplate = useHasPlugin("blockTemplate");
  const hasEmoji = useHasPlugin("emoji");
  const hasStrike = useHasPlugin("strikethrough");

  const extensions = [
    Dropcursor.configure(),
    RichTextKit.configure({
      ...(!hasStrike && {
        strike: false,
      }),
      typographicRules: {
        typographicalCorrection,
        italicsBetweenQuotes,
      },
      emoji: hasEmoji
        ? {
            emojis,
            suggestion: {
              render: renderEmojiSuggestionList,
            },
          }
        : false,
      blockTemplate: hasBlockTemplate ? { blockTemplates } : false,
      placeholder: { placeholder },
    }),
  ];

  const content = value
    ? convertRichTextNodeToContent(value, extensions)
    : null;

  const handleUpdate = ({ editor }: { editor: Editor }) => {
    onChange(convertContentToRichTextNode(editor.getJSON(), extensions));
  };

  return (
    <div {...props} className={cn("w-full", props.className)}>
      <EditorProvider
        editable={!disabled}
        extensions={extensions}
        content={content}
        onUpdate={handleUpdate}
      >
        <Toolbar>
          <HeadingControl level={2} label="Intertitre (niveau 2)" />
          <OrderedListControl />
          <BulletListControl />
          <BlockquoteControl />
          {hasBlockTemplate && <BlockTemplatesControl />}
          <ToolbarSeparator />
          <SpecialCharControl
            render={
              <ToolbarItem label="Insérer un caractère spécial">
                <Omega />
              </ToolbarItem>
            }
          />
          <FrequentlyUsedControl />
        </Toolbar>
        <Toolbar>
          <UndoControl />
          <RedoControl />
          <ToolbarSeparator />
          {hasMerciApp && (
            <MerciAppSpellCheckControl
              render={
                <ToolbarItem label="Corriger le texte avec MerciApp">
                  <SpellCheck />
                </ToolbarItem>
              }
            />
          )}
          {hasProlexis && (
            <ProlexisSpellCheckControl
              render={
                <ToolbarItem label="Corriger le texte avec Prolexis">
                  <SpellCheck />
                </ToolbarItem>
              }
            />
          )}
          {(hasProlexis || hasMerciApp) && <ToolbarSeparator />}
          <BoldControl />
          <ItalicControl />
          <UnderlineControl />
          {hasStrike && <StrikethroughControl />}
          <SubscriptControl />
          <SuperscriptControl />
          <ToggleCaseControl />
          <LinkControl />
          <RemoveStyleControl />
        </Toolbar>
        <DragHandle />
        <RichEditorContent
          intent={invalid ? "danger" : undefined}
          scale="base"
          className={`[&_.editor[contenteditable="true"]]:pl-10`}
        >
          <LinkEditorMenu />
          {!disabled && <AddLineButton className="order-1 mb-2" />}
        </RichEditorContent>
      </EditorProvider>
    </div>
  );
}, deepEqual);

interface RichTextEditorProps extends ComponentPropsWithoutRef<"div"> {
  placeholder?: string;
  disabled?: boolean;
  invalid?: boolean;
  value: any;
  onChange: (value: any) => void;
}

export const RichTextField: FC<RichTextFieldProps> = ({
  name,
  label,
  hint,
  placeholder,
  disabled,
  readOnly,
  ...options
}) => {
  const field = useRichTextEditorField(name, options);

  const fieldProps = {
    ...field,
    placeholder,
    disabled,
    readOnly,
  };
  const hasExperimentalFeature = useHasExperimentalFeature(
    "next-gen-rich-text-fields",
  );
  return (
    <FieldGroup {...field}>
      <FieldLabel {...field}>{label}</FieldLabel>
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <FieldControl
        as={hasExperimentalFeature ? RichTextEditor : DetachedEditor}
        {...fieldProps}
        className="rounded-sm border border-grey-border-light bg-white"
      />
    </FieldGroup>
  );
};

interface RichTextFieldProps {
  name: string;
  label?: string;
  hint?: string;
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  [key: string]: any;
}
