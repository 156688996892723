import { useMemo } from "react";
import { Button } from "swash/Button";
import type { DialogStore } from "swash/Dialog";
import { IoSparklesSharp } from "swash/Icon";
import { useToaster } from "swash/Toast";

import { Form } from "@/components/forms/Form";
import { FormErrorToaster } from "@/components/forms/FormError";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { useHasPermission } from "@/containers/User";
import { DateField, StringField } from "@/containers/admin/CRUD";
import type {
  ImageForm_imageFragment as ImageFragment,
  ImageForm_imagesQuery as ImagesQuery,
  UpdateImagesInput,
} from "@/gql-types";

import { FieldName, fieldsMap } from "../ImageFieldsMap";
import { RestrictionSection } from "../ImageRestrictions";
import { TypeField } from "../ImageTypeField";
import { FooterTeleporter } from "./ImageMultipleForm";
import { renderPlaceholder } from "./utils";

export function ImageMultipleFormContent({
  imageIds,
  data,
  updateImages,
  onCloseImportDialog,
  store,
}: {
  imageIds?: number[];
  data?: ImagesQuery;
  updateImages: (args: {
    variables: { input: UpdateImagesInput };
  }) => Promise<unknown>;
  onCloseImportDialog?: () => void;
  store: DialogStore;
}) {
  const toaster = useToaster();
  const images = useMemo(() => data?.images.nodes ?? [], [data]);

  const initialValues = useMemo(() => {
    if (!images.length) return {};

    return Object.values(fieldsMap).reduce<{ [field in FieldName]?: any }>(
      (values, field) => {
        const { name, format = (v: any) => v ?? "" } = field;

        const getValue = () => {
          const value = images[0]![name];
          const same = images.every((image) => image[name] === value);

          return format(same ? value : null);
        };

        return {
          ...values,
          [name]: getValue(),
        };
      },
      {},
    );
  }, [images]);

  return (
    <Form
      collaborative
      initialValues={initialValues}
      onSubmit={async (values, form) => {
        if (!data) return;

        const diff = Object.fromEntries(
          Object.entries(form.getState().modified!)
            .map(([key, modified]) =>
              modified ? [key, values[key as FieldName]] : false,
            )
            .filter((val): val is [FieldName, any] => Boolean(val)),
        );

        try {
          await updateImages({
            variables: {
              input: { ids: imageIds ?? [], ...diff },
            },
          });
          store.hide();
          onCloseImportDialog?.();
        } catch {
          toaster.danger("L’édition des images a échoué");
        }
      }}
    >
      <FormErrorToaster />
      <Content images={images} />
      <FooterTeleporter.Source>
        <div className="flex gap-4">
          <Button
            type="button"
            variant="secondary"
            appearance="text"
            onClick={store.hide}
          >
            Annuler
          </Button>
          <FormSubmit>
            Confirmer l’édition de {imageIds?.length ?? 0} images
          </FormSubmit>
        </div>
      </FooterTeleporter.Source>
    </Form>
  );
}

function GroupTypeField({ images }: { images: ImageFragment[] }) {
  const name = fieldsMap.type.name;
  const same = images.every((image) => image[name] === images[0]![name]);
  const disabled = !same;

  return (
    <div className="flex flex-col gap-2">
      <TypeField
        // @ts-expect-error JS component
        name={name}
        label={fieldsMap.type.label}
        placeholder={renderPlaceholder(images, name)}
        disabled={disabled}
        required={!disabled}
      />
      {disabled ? (
        <div className="text-sm text-grey-on">
          <IoSparklesSharp className="inline" /> Sirius a détecté
          automatiquement plusieurs types d’images différents parmi les images
        </div>
      ) : null}
    </div>
  );
}

function Content({ images }: { images: ImageFragment[] }) {
  const hasEditUseRightsPermission = useHasPermission([
    "article:image:editUseRights",
  ]);

  return (
    <div className="flex flex-col gap-4">
      <GroupTypeField images={images} />
      {/* @ts-expect-error JS component */}
      <DateField
        name={fieldsMap.shootingDate.name}
        label={fieldsMap.shootingDate.label}
        placeholder={renderPlaceholder(images, fieldsMap.shootingDate.name)}
        disabled={!hasEditUseRightsPermission}
      />
      <StringField
        name={fieldsMap.city.name}
        label={fieldsMap.city.label}
        placeholder={renderPlaceholder(images, fieldsMap.city.name)}
      />
      <StringField
        name={fieldsMap.credit.name}
        label={fieldsMap.credit.label}
        rich
        placeholder={renderPlaceholder(images, fieldsMap.credit.name)}
      />
      <StringField
        name={fieldsMap.photographer.name}
        label={fieldsMap.photographer.label}
        placeholder={renderPlaceholder(images, fieldsMap.photographer.name)}
      />
      <StringField
        name={fieldsMap.keywords.name}
        label={fieldsMap.keywords.label}
        placeholder={renderPlaceholder(images, fieldsMap.keywords.name)}
      />
      <div className="flex flex-col gap-2">
        <RestrictionSection
          // @ts-expect-error JS component
          images={images}
        />
      </div>
    </div>
  );
}
