import { TypedDocumentNode, gql } from "@apollo/client";
import "moment/locale/fr";
import { createTeleporter } from "react-teleporter";
import type { DialogStore } from "swash/Dialog";

import { useSafeMutation, useSafeQuery } from "@/containers/Apollo";
import type {
  ImageForm_imagesQuery,
  ImageForm_imagesQueryVariables,
  ImageForm_updateImagesMutation,
  ImageForm_updateImagesMutationVariables,
} from "@/gql-types";

import { RouteError } from "../../Router";
import { ImageFragment } from "../ImageFragments";
import { ImageMultipleFormContent } from "./ImageMultipleFormContent";

const ImagesQuery: TypedDocumentNode<
  ImageForm_imagesQuery,
  ImageForm_imagesQueryVariables
> = gql`
  query ImageForm_images($ids: [Int!]!) {
    images(where: { id: { in: $ids } }) {
      nodes {
        id
        ...ImageForm_image
      }
    }
  }
  ${ImageFragment}
`;

export const FooterTeleporter = createTeleporter();

const UpdateImagesMutation: TypedDocumentNode<
  ImageForm_updateImagesMutation,
  ImageForm_updateImagesMutationVariables
> = gql`
  mutation ImageForm_updateImages($input: UpdateImagesInput!) {
    updateImages(input: $input) {
      id
      ...ImageForm_image
    }
  }
  ${ImageFragment}
`;

type ImageMultipleFormProps = {
  imageIds?: number[];
  store: DialogStore;
  onCloseImportDialog?: () => void;
  children: (props: {
    title: React.ReactNode;
    body: React.ReactNode;
    footer: React.ReactNode;
  }) => React.ReactNode;
};

function ImageMultipleForm({
  imageIds,
  children,
  store,
  ...props
}: ImageMultipleFormProps & { data?: ImageForm_imagesQuery }) {
  const [updateImages] = useSafeMutation(UpdateImagesMutation);

  return (
    <div className="contents">
      {children({
        title: `Éditer ${imageIds?.length ?? 0} images`,
        body: (
          <ImageMultipleFormContent
            imageIds={imageIds}
            updateImages={updateImages}
            store={store}
            {...props}
          />
        ),
        footer: <FooterTeleporter.Target />,
      })}
    </div>
  );
}

function ImageMultipleFormDataLoader({
  imageIds,
  store,
  ...props
}: ImageMultipleFormProps) {
  const { data } = useSafeQuery(ImagesQuery, {
    variables: { ids: imageIds ?? [] },
  });

  if (data && !data.images.nodes.length) {
    throw new RouteError("Images not found", 404);
  }

  return (
    <ImageMultipleForm
      imageIds={imageIds}
      data={data}
      store={store}
      {...props}
    />
  );
}

export { ImageMultipleFormDataLoader as ImageMultipleForm };
