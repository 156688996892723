import { gql } from "@apollo/client";

import { FieldsetField } from "@/components/fields/Fieldset";

import { useFormPeriodical } from "../util";
import {
  PeriodicalField,
  PeriodicalFieldPeriodicalFragment,
} from "./Periodical";
import { PeriodicalEditionDateField } from "./PeriodicalEditionDate";
import { PeriodicalSectionField } from "./PeriodicalSection";
import { PeriodicalSectionLayoutField } from "./PeriodicalSectionLayout";

export const PrintFieldsQueryFragment = gql`
  fragment PrintFieldsQueryFragment on Periodical {
    id
    enabled
    exportDisabled
    editionType
    ...PeriodicalFieldPeriodicalFragment
    periodicity {
      weekDays
    }
    releases {
      nodes {
        id
        label
        releaseDate
        enabled
        sections {
          id
        }
      }
    }
  }

  ${PeriodicalFieldPeriodicalFragment}
`;

/**
 * @param {object} props
 * @param {?} props.data
 * @param {boolean} [props.disabled]
 * @param {boolean} [props.required]
 * @param {boolean} [props.selectableDisabled]
 * @param {?} [props.modal]
 */
export function PrintFields({
  data,
  disabled,
  required,
  selectableDisabled,
  modal,
  clearable = true,
}) {
  const periodicals = data?.periodicals ?? null;
  const periodical = useFormPeriodical({ periodicals });

  return (
    <>
      <FieldsetField>
        <PeriodicalField
          required={required}
          disabled={disabled}
          periodicals={periodicals}
          clearable={clearable}
          modal={modal}
          selectableDisabled={selectableDisabled}
        />
      </FieldsetField>
      <PeriodicalSectionField
        required={required}
        disabled={disabled}
        modal={modal}
        periodical={periodical}
      >
        {({ children }) => <FieldsetField>{children}</FieldsetField>}
      </PeriodicalSectionField>
      <PeriodicalEditionDateField
        required={required}
        disabled={disabled}
        periodical={periodical}
        modal={modal}
        selectableDisabled={selectableDisabled}
      >
        {({ children }) => <FieldsetField>{children}</FieldsetField>}
      </PeriodicalEditionDateField>
      <PeriodicalSectionLayoutField
        required={required}
        disabled={disabled}
        modal={modal}
      >
        {({ children }) => <FieldsetField>{children}</FieldsetField>}
      </PeriodicalSectionLayoutField>
    </>
  );
}
