import { gql } from "@apollo/client";
import { Tooltip, TooltipProps } from "swash/Tooltip";

import { UserAvatar, UserAvatarProps } from "@/containers/user/UserAvatar";
import { UserCard, UserCardUser } from "@/containers/user/UserCard";

export type UserHoverCardUser = UserCardUser & { fullName: string };

type UserHoverCardTooltipProps = Omit<TooltipProps, "tooltip"> & {
  user: UserHoverCardUser;
};

export const UserHoverCardTooltip = ({
  user,
  children,
  ...props
}: UserHoverCardTooltipProps) => (
  <Tooltip tooltip={<UserCard user={user} />} nude {...props}>
    {children}
  </Tooltip>
);

UserHoverCardTooltip.fragments = {
  user: gql`
    fragment UserHoverCardTooltip_user on User {
      id
      ...UserCard_user
    }
    ${UserCard.fragments.user}
  `,
};

type UserHoverCardAvatarProps = UserAvatarProps & {
  user: UserHoverCardUser;
};

export const UserHoverCardAvatar = ({
  user,
  ...props
}: UserHoverCardAvatarProps) => {
  return (
    <UserHoverCardTooltip user={user}>
      <UserAvatar user={user} {...props} />
    </UserHoverCardTooltip>
  );
};

UserHoverCardAvatar.fragments = {
  user: gql`
    fragment UserHoverCardAvatar_user on User {
      id
      fullName
      ...UserHoverCardTooltip_user
      ...UserAvatar_user
    }
    ${UserHoverCardTooltip.fragments.user}
    ${UserAvatar.fragments.user}
  `,
};
