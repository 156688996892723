import { TypedDocumentNode, gql } from "@apollo/client";

import { useSafeQuery } from "@/containers/Apollo";
import {
  BlockTemplatesQueryQuery,
  useBlockTemplates_blockTemplateFragment,
} from "@/gql-types";

export type BlockTemplate = useBlockTemplates_blockTemplateFragment;

const BlockTemplateFragment = gql`
  fragment useBlockTemplates_blockTemplate on BlockTemplate {
    id
    name
    title
    type
    editorType
    nodeType {
      name
      attrs
    }
    css
    enabled
  }
`;

const BlockTemplatesQuery: TypedDocumentNode<BlockTemplatesQueryQuery, never> =
  gql`
    query BlockTemplatesQuery {
      blockTemplates {
        nodes {
          id
          ...useBlockTemplates_blockTemplate
        }
      }
    }
    ${BlockTemplateFragment}
  `;

export function useBlockTemplates() {
  const { data } = useSafeQuery(BlockTemplatesQuery);
  return data?.blockTemplates.nodes ?? [];
}
