import moment from "moment";
import { memo, useCallback, useMemo, useState } from "react";
import { useField, useForm, useFormState } from "react-final-form";
import {
  ExpandDisclosure,
  ExpandDisclosureContent,
  useExpandDisclosureStore,
} from "swash/ExpandDisclosure";
import { SelectLabel, SelectPopover } from "swash/Select";
import { Checkbox } from "swash/controls/Checkbox";
import { FormCheckbox } from "swash/form/FormCheckbox";
import { cn } from "swash/utils/classNames";
import { useId } from "swash/utils/hooks";
import { useHovered } from "swash/utils/useHovered";

import {
  DateSelectState,
  RangeDatePickerLabel,
  parseRange,
} from "@/components/controls/SelectDatePicker";
import { useSwitchField } from "@/components/fields/SwitchField";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import {
  InnerSelectDatePopover,
  SelectDateFilter,
  formatDate,
  generatePresets,
  parseDate,
  useDateFiltersFieldState,
} from "@/containers/admin/CRUD/filtersFields/DateFiltersField";

const presets = generatePresets([
  { label: "Aujourd'hui", from: 0 },
  { label: "Les 7 derniers jours", from: -6, to: 0 },
  { label: "Les 7 prochains jours", from: 0, to: 6 },
]);

export const getCurrentWeek = () => {
  const weekRange = parseRange({
    from: moment().format(),
    to: moment().add(6, "days").format(),
  });
  return weekRange;
};

const getPlannedDateInitialValue = () => {
  return {
    undated: false,
    plannedDate: null,
    isDailyPlanned: false,
    timeSlots: [],
    isUrgent: false,
  };
};

export type TimeSlot = {
  id: string;
  value: string;
  label: string;
};

export const useTimeSlots = (): TimeSlot[] => {
  const { publicationSlots } = useRemoteConfig();

  return useMemo(() => {
    return publicationSlots
      .map((slot, index) => [slot, [...publicationSlots, 24][index + 1]])
      .map(([from, to]) => {
        const start = from?.toString().padStart(2, "0");
        const end = to?.toString().padStart(2, "0");
        const label = `${start}h ￫ ${end === "24" ? "00" : end}h`;
        const value = `${start}-${end}`;
        return { id: value, value, label };
      });
  }, [publicationSlots]);
};

const name = "plannedDate";

type SectionWrapperProps = {
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const SectionWrapper = (props: SectionWrapperProps) => {
  const { className, ...rest } = props;
  return (
    <div
      className={cn(className, "border-t border-gray-200 first:border-t-0")}
      {...rest}
    />
  );
};

type CheckboxControlProps = {
  checked: boolean;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const CheckboxControl = (props: CheckboxControlProps) => {
  const { checked, children, ...rest } = props;
  const [containerRefHandler, hovered] = useHovered();

  return (
    <div
      ref={containerRefHandler as any}
      className={cn("flex w-full items-center gap-2 p-3")}
      {...rest}
    >
      <Checkbox
        data-hovered={hovered ? "" : undefined}
        readOnly
        checked={checked}
      />
      {children}
    </div>
  );
};

type DatePickerFilterProps = {
  select: DateSelectState;
  children: React.ReactNode;
};

const DatePickerFilter = (props: DatePickerFilterProps) => {
  const { select, children } = props;
  const plannedDateField = useField("plannedDate.plannedDate", {
    parse: parseDate,
    format: formatDate,
  });
  const open = Boolean(plannedDateField.input.value);
  const expandedDisclosure = useExpandDisclosureStore({
    open,
    setOpen: (open) => {
      plannedDateField.input.onChange(open ? getCurrentWeek() : null);
    },
  });

  const { initialValues } = useFormState();

  const timeSlots = useTimeSlots();

  const hasTimeFilters =
    initialValues.plannedDate.timeSlots !== undefined ||
    initialValues.plannedDate.isDailyPlanned !== undefined ||
    initialValues.plannedDate.isUrgent !== undefined;

  return (
    <>
      <ExpandDisclosure
        {...expandedDisclosure}
        style={{
          width: !hasTimeFilters || !timeSlots.length ? "452px" : "680px",
        }}
        className={cn(open && "bg-blue-bg-light")}
      >
        <CheckboxControl checked={open}>Daté</CheckboxControl>
      </ExpandDisclosure>
      <ExpandDisclosureContent
        {...expandedDisclosure}
        className="overflow-hidden"
      >
        <InnerSelectDatePopover select={select}>
          {hasTimeFilters ? children : []}
        </InnerSelectDatePopover>
      </ExpandDisclosureContent>
    </>
  );
};

const UndatedFilter = () => {
  const switchField = useSwitchField("plannedDate.undated");
  const { checked, onChange } = switchField.state.field.input;
  const handleOnClick = () => {
    onChange(!checked);
  };

  return (
    <button
      onClick={handleOnClick}
      className={cn(checked && "bg-blue-bg-light", "w-full")}
    >
      <CheckboxControl checked={checked ?? false}>Non daté</CheckboxControl>
    </button>
  );
};

const TimeSlotField = (props: TimeSlot) => {
  const { id, label, value } = props;
  const values = useSubscribeFormValue("plannedDate.timeSlots");
  const timeSlotsValues: string[] = useMemo(() => values ?? [], [values]);
  const form = useForm();

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        form.change(
          "plannedDate.timeSlots",
          [...timeSlotsValues, value].sort(),
        );
      } else {
        form.change(
          "plannedDate.timeSlots",
          timeSlotsValues.filter((slot) => slot !== value),
        );
      }
    },
    [form, value, timeSlotsValues],
  );

  return (
    <FormCheckbox>
      <Checkbox
        id={id}
        onChange={handleChange}
        checked={timeSlotsValues.includes(value)}
        scale="sm"
      />
      <label htmlFor={id} className="flex-1 font-accent text-sm">
        {label}
      </label>
    </FormCheckbox>
  );
};

const IsUrgentFilter = () => {
  const field = useField("plannedDate.isUrgent", {
    type: "checkbox",
  });
  const { initialValues } = useFormState();

  const id = useId();

  if (initialValues.plannedDate.isUrgent === undefined) return null;

  return (
    <FormCheckbox>
      <Checkbox id={id} {...field.input} />
      <label htmlFor={id} className="flex-1 font-accent text-base">
        ASAP
      </label>
    </FormCheckbox>
  );
};

export const TimeSlotsFilter = () => {
  const id = useId();
  const field = useField("plannedDate.timeSlots");
  const { value, onChange } = field.input;
  const [checked, setChecked] = useState(Boolean(value.length));

  const timeSlots = useTimeSlots();

  const handleChange = useCallback(() => {
    setChecked((checked: boolean) => !checked);
    if (checked) {
      onChange([]);
    }
  }, [checked, setChecked, onChange]);

  const { initialValues } = useFormState();

  if (initialValues.plannedDate.timeSlots === undefined) return null;

  return (
    <div>
      <FormCheckbox>
        <Checkbox id={id} checked={checked} onChange={handleChange} />
        <label htmlFor={id} className="text-md flex-1 font-accent text-base">
          Créneaux horaires
        </label>
      </FormCheckbox>
      <div className="pl-6">
        {checked
          ? timeSlots.map((slot) => (
              <TimeSlotField key={slot.label} {...slot} />
            ))
          : null}
      </div>
    </div>
  );
};

export const DailyPlannedFilter = () => {
  const field = useField("plannedDate.isDailyPlanned", {
    type: "checkbox",
  });
  const id = useId();

  const { initialValues } = useFormState();

  if (initialValues.plannedDate.isDailyPlanned === undefined) return null;

  return (
    <FormCheckbox>
      <Checkbox id={id} {...field.input} />
      <label htmlFor={id} className="flex-1 font-accent text-base">
        Sans horaires
      </label>
    </FormCheckbox>
  );
};

const PlannedDateFilters = (props: {
  select: DateSelectState;
  children: React.ReactNode;
}) => {
  const { select, children } = props;
  return (
    <div className="-m-4 flex flex-col">
      <SectionWrapper>
        <DatePickerFilter select={select}>{children}</DatePickerFilter>
      </SectionWrapper>
      <SectionWrapper>
        <UndatedFilter />
      </SectionWrapper>
    </div>
  );
};

export const DateFilter = memo(
  (props: { children?: React.ReactNode; label: string }) => {
    const { children, label } = props;
    const field = useField("plannedDate");
    const undated = field.input.value.undated;

    const select = useDateFiltersFieldState({
      name: "plannedDate.plannedDate",
      presets,
      label,
      labelSelector: (value) => {
        if (!undated) {
          return <RangeDatePickerLabel value={value} />;
        }
        return "Non daté";
      },
    });

    return (
      <div>
        <SelectLabel store={select.select.select} label={label} />
        <SelectDateFilter select={select.select} placeholder={label} />
        <SelectPopover
          store={select.select.select}
          className="!p-4"
          style={{ maxHeight: undefined }}
        >
          <PlannedDateFilters select={select.select}>
            {children}
          </PlannedDateFilters>
        </SelectPopover>
      </div>
    );
  },
);

type FilterProps = {
  label?: string;
};

const Filter = (props: FilterProps) => {
  const { label = "Date de publication num." } = props;
  const timeSlots = useTimeSlots();

  if (!timeSlots.length) return <DateFilter label={label} />;

  return (
    <DateFilter label={label}>
      <IsUrgentFilter />
      <TimeSlotsFilter />
      <DailyPlannedFilter />
    </DateFilter>
  );
};

export const PlannedDateFilter = {
  name,
  element: <Filter />,
  initialValue: getPlannedDateInitialValue(),
  spread: true,
};
