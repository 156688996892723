import { DocumentNode, gql } from "@apollo/client";
import { ForwardRefExoticComponent, forwardRef } from "react";
import { cn } from "swash/utils/classNames";

import { Image } from "@/components/Image";
import type { UserAvatar_userFragment } from "@/gql-types";

const backgroundRandomColors = [
  "#2a3b4c",
  "#10418e",
  "#4527a0",
  "#8ca1ee",
  "#65b7d7",
  "#65b793",
  "#00796b",
  "#9c1258",
  "#c20006",
  "#ff3d44",
  "#ffb83d",
  "#f58f00",
];

export type UserAvatarUser = UserAvatar_userFragment;

export type UserAvatarProps = {
  user: UserAvatarUser;
  size?: number;
  className?: string;
};

type InitialsAvatarProps = UserAvatarProps;

const InitialsAvatar = forwardRef<HTMLDivElement, InitialsAvatarProps>(
  ({ user, size, className }, ref) => {
    const randomIndex = user.id % backgroundRandomColors.length;
    return (
      <div
        ref={ref}
        className={cn(
          "flex shrink-0 select-none items-center justify-center rounded-full",
          className,
        )}
        style={{
          backgroundColor: backgroundRandomColors[randomIndex],
          width: size,
          height: size,
        }}
      >
        <svg width="100%" height="100%" viewBox="-50 -66 100 100">
          <text fill="white" fontWeight="600" textAnchor="middle" fontSize="50">
            {user.initials}
          </text>
        </svg>
      </div>
    );
  },
);
if (process.env["NODE_ENV"] !== "production") {
  InitialsAvatar.displayName = "InitialsAvatar";
}

export const UserAvatar = forwardRef<
  HTMLDivElement | HTMLImageElement,
  UserAvatarProps
>(({ className, user, size = 32 }, ref) => {
  if (!user.image) {
    return (
      <InitialsAvatar user={user} size={size} className={className} ref={ref} />
    );
  }
  const avatar = size <= 32 ? user.image.avatar : user.image.avatarHD;
  return (
    <Image
      ref={ref as React.ForwardedRef<HTMLImageElement>}
      {...avatar}
      alt={user.fullName}
      width={size}
      height={size}
      className={cn("rounded-full", className)}
    />
  );
}) as ForwardRefExoticComponent<UserAvatarProps> & {
  fragments: { user: DocumentNode };
};
if (process.env["NODE_ENV"] !== "production") {
  UserAvatar.displayName = "UserAvatar";
}

UserAvatar.fragments = {
  user: gql`
    fragment UserAvatar_user on User {
      id
      fullName
      initials
      image {
        id
        avatar: fixed(width: 32, height: 32) {
          ...Image_fixed
        }
        avatarHD: fixed(width: 128, height: 128) {
          ...Image_fixed
        }
      }
    }

    ${Image.fragments.fixed}
  `,
};
