import { Blockquote as TipTapBlockquote } from "@tiptap/extension-blockquote";

/**
 * This extension allows you to create blockquotes.
 * This requires the `Italic` extension.
 */
export const Blockquote = TipTapBlockquote.extend({
  addCommands() {
    return {
      setBlockquote:
        () =>
        ({ chain, state }) => {
          const { selection } = state;
          return chain()
            .selectParentNode()
            .wrapIn(this.name)
            .setItalic()
            .setTextSelection(selection)
            .run();
        },
      toggleBlockquote:
        () =>
        ({ chain, state, editor }) => {
          const { selection } = state;
          const isActive = editor.isActive("blockquote");
          return chain()
            .selectParentNode()
            .toggleWrap(this.name)
            .command(({ commands }) => {
              if (isActive) {
                return commands.unsetItalic();
              } else {
                return commands.setItalic();
              }
            })
            .setTextSelection(selection)
            .run();
        },
      unsetBlockquote:
        () =>
        ({ chain, state }) => {
          const { selection } = state;
          return chain()
            .selectParentNode()
            .lift(this.name)
            .unsetItalic()
            .setTextSelection(selection)
            .run();
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-m": () => this.editor.commands.toggleBlockquote(),
    };
  },
});
