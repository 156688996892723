import { noop } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { RemoteEditImageMultipleDialogProvider } from "./ImageEditMultipleDialog";

type MultiSelectHandler = (options: {
  listImageIds: number[];
  imageId: number;
  select: boolean;
}) => void;

type ImageEditMultipleContextValue = {
  selectedImageIds: number[];
  unselectAllImages: () => void;
  handleMultiSelect?: MultiSelectHandler;
};

const ImageEditMultipleContext = createContext<ImageEditMultipleContextValue>({
  selectedImageIds: [],
  unselectAllImages: noop,
});

export function ImageEditMultipleProvider({
  children,
  selectable = true,
}: {
  children: React.ReactNode;
  selectable?: boolean;
}) {
  const [selectedImageIds, setSelectedImageIds] = useState<number[]>([]);
  const [shiftKeyOn, setShiftKeyOn] = useState(false);

  const lastUnselectedImageIdRef = useRef<number | null>(null);

  const unselectAllImages = useCallback(() => {
    setSelectedImageIds([]);
  }, [setSelectedImageIds]);

  const handleMultiSelect = useCallback<MultiSelectHandler>(
    ({ listImageIds, imageId, select }) => {
      setSelectedImageIds((imageIds) => {
        if (shiftKeyOn && imageIds.length) {
          const lastUnselectedImageId = lastUnselectedImageIdRef.current;
          const imageIndex = listImageIds.indexOf(imageId);
          const lastSelectedImageId = imageIds[imageIds.length - 1];
          const lastImageId = select
            ? lastSelectedImageId
            : (lastUnselectedImageId ?? lastSelectedImageId);
          const lastImageIndex =
            lastImageId != null ? listImageIds.indexOf(lastImageId) : -1;
          const forward = imageIndex > lastImageIndex;
          const start = forward ? lastImageIndex : imageIndex;
          const end = forward ? imageIndex + 1 : lastImageIndex + 1;
          const selectedImageIds = forward
            ? listImageIds.slice(start, end)
            : listImageIds.slice(start, end).reverse();

          if (select) {
            return [...new Set([...imageIds, ...selectedImageIds])];
          } else {
            return imageIds.filter(
              (id) => !listImageIds.slice(start, end).includes(id),
            );
          }
        } else {
          if (select) {
            return [...imageIds, imageId];
          } else {
            return imageIds.filter((id) => id !== imageId);
          }
        }
      });
      lastUnselectedImageIdRef.current = select ? null : imageId;
    },
    [shiftKeyOn],
  );

  const handleKeyEvent = useCallback((event: KeyboardEvent) => {
    setShiftKeyOn(event.shiftKey);
  }, []);

  useEffect(() => {
    if (!selectable) return;
    document.addEventListener("keydown", handleKeyEvent, { passive: true });
    document.addEventListener("keyup", handleKeyEvent, { passive: true });
    return () => {
      document.removeEventListener("keydown", handleKeyEvent);
      document.removeEventListener("keyup", handleKeyEvent);
    };
  }, [handleKeyEvent, selectable]);

  useEffect(() => {
    if (!selectedImageIds.length) {
      lastUnselectedImageIdRef.current = null;
    }
  }, [selectedImageIds]);

  return (
    <ImageEditMultipleContext.Provider
      value={{
        selectedImageIds,
        unselectAllImages,
        handleMultiSelect: selectable ? handleMultiSelect : undefined,
      }}
    >
      {/* @ts-expect-error JS component */}
      <RemoteEditImageMultipleDialogProvider>
        {children}
      </RemoteEditImageMultipleDialogProvider>
    </ImageEditMultipleContext.Provider>
  );
}

export const useImageEditMultiple = () => {
  return useContext(ImageEditMultipleContext);
};
