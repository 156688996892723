import { Extension, getNodeType, isList } from "@tiptap/core";

import { getSelectedBlocks } from "../helpers/getSelectedBlocks";

type BlockTemplateData = {
  name: string;
  css?: Record<string, string> | null;
  nodeType: {
    name: string;
    attrs?: Record<string, any> | null;
  };
};

export interface BlockTemplateOptions {
  types: string[];
  blockTemplates: BlockTemplateData[];
}

export const BlockTemplate = Extension.create<BlockTemplateOptions>({
  name: "blockTemplate",

  addOptions() {
    return {
      types: [
        "paragraph",
        "heading",
        "blockquote",
        "listItem",
        "orderedList",
        "bulletList",
      ],
      blockTemplates: [],
    };
  },

  addStorage() {
    return {
      blockTemplates: this.options.blockTemplates,
      types: this.options.types,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          styleName: {
            default: null,
            renderHTML: (attr) => {
              const styleName = attr.styleName;
              const blockTemplate = findBlockTemplate(
                styleName,
                this.storage.blockTemplates,
              );
              return blockTemplate?.css
                ? {
                    "data-style-name": styleName,
                    style: objectToCss(blockTemplate.css),
                  }
                : { "data-style-name": styleName };
            },
            parseHTML: (el) => el.getAttribute("data-style-name"),
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setBlockTemplate:
        (styleName) =>
        ({ commands, chain, dispatch, state }) => {
          const nodes = getSelectedBlocks(state);
          if (
            !nodes.every((node) => this.options.types.includes(node.type.name))
          )
            return false;
          const blockTemplate = findBlockTemplate(
            styleName,
            this.storage.blockTemplates,
          );
          if (
            styleName &&
            (!blockTemplate ||
              !this.options.types.includes(blockTemplate.nodeType.name) ||
              // Ensure that the heading block template is a valid heading level
              (blockTemplate.nodeType.name === "heading" &&
                !this.editor.extensionManager.extensions
                  .find((ext) => ext.name === "heading")
                  ?.options.levels.includes(
                    blockTemplate.nodeType.attrs?.level,
                  )))
          )
            return false;

          if (dispatch) {
            if (!styleName || !blockTemplate) return false;
            this.options.types.forEach((type) => {
              chain().liftAll(type);
            });
            const { nodeType } = blockTemplate;
            const type = getNodeType(nodeType.name, state.schema);
            if (type.isTextblock) {
              return chain()
                .setNode(type, { ...nodeType.attrs, styleName })
                .run();
            } else {
              if (
                isList(nodeType.name, this.editor.extensionManager.extensions)
              ) {
                chain().wrapInList(nodeType.name, {
                  ...nodeType.attrs,
                  styleName,
                });
              } else {
                chain().wrapIn(type, {
                  ...nodeType.attrs,
                  styleName,
                });
              }
              // Remove the styleName attribute from other block types
              this.options.types
                .filter((t) => t !== nodeType.name)
                .forEach((t) => chain().resetAttributes(t, "styleName"));
            }
            return chain().run();
          }
          return this.options.types
            .map((type) => commands.updateAttributes(type, { styleName }))
            .every((response) => response);
        },

      unsetBlockTemplate:
        () =>
        ({ commands }) =>
          this.options.types
            .map((type) => commands.resetAttributes(type, "styleName"))
            .every(Boolean),
    };
  },
});

function objectToCss(obj: Record<string, string>) {
  return Object.entries(obj)
    .map(
      ([k, v]) => `${k.replace(/[A-Z]/g, (m) => `-${m.toLowerCase()}`)}:${v}`,
    )
    .join(";");
}

function findBlockTemplate(
  name: string | undefined,
  blockTemplates: BlockTemplateData[],
) {
  return name ? blockTemplates.find((tpl) => tpl.name === name) : undefined;
}

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    blockTemplate: {
      setBlockTemplate: (blockTemplateName?: string) => ReturnType;
      unsetBlockTemplate: () => ReturnType;
    };
  }
}
