import { mergeAttributes } from "@tiptap/core";
import { Link as TipTapLink } from "@tiptap/extension-link";

export const Link = TipTapLink.extend({
  addAttributes() {
    return {
      href: {
        default: null,
        parseHTML(element) {
          return element.getAttribute("href");
        },
      },
      affiliate: {
        default: false,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        class: "link",
        "data-affiliate": HTMLAttributes.affiliate ? "" : undefined,
      }),
      0,
    ];
  },

  addKeyboardShortcuts() {
    return {
      "Mod-k": () => this.editor.commands.toggleLink({ href: "" }),
    };
  },
});
