import { gql } from "@apollo/client";

import { UserAvatar, UserAvatarUser } from "@/containers/user/UserAvatar";
import {
  UserHoverCardTooltip,
  UserHoverCardUser,
} from "@/containers/user/UserHoverCard";

export type UserOptionLabelUser = UserHoverCardUser &
  UserAvatarUser & {
    fullName: string;
  };

export type UserOptionLabelProps = {
  user: UserOptionLabelUser;
  /**
   * @default 16
   */
  size?: number;
};

export const UserOptionLabel = ({ user, size = 16 }: UserOptionLabelProps) => {
  return (
    <UserHoverCardTooltip user={user} placement="right" gutter={16}>
      <div
        className="flex w-full items-center gap-2"
        aria-label={user.fullName}
      >
        <UserAvatar user={user} size={size} /> {user.fullName}
      </div>
    </UserHoverCardTooltip>
  );
};

UserOptionLabel.fragments = {
  user: gql`
    fragment UserOptionLabel_user on User {
      fullName
      ...UserHoverCardTooltip_user
      ...UserAvatar_user
    }
    ${UserHoverCardTooltip.fragments.user}
    ${UserAvatar.fragments.user}
  `,
};
