import { gql } from "@apollo/client";
import { memo, useCallback } from "react";
import { IoExpand, IoImage } from "swash/Icon";
import {
  PreviewLink,
  PreviewLinkContent,
  PreviewLinkHovering,
} from "swash/PreviewLink";
import { Checkbox } from "swash/controls/Checkbox";
import { cn } from "swash/utils/classNames";

import {
  CardListItem,
  CardListItemEditDisclosure,
  CardListItemThumbnail,
} from "@/components/CardListItem";
import { Image } from "@/components/Image";
import { RemoteEditImageDialogDisclosure } from "@/containers/image/ImageEditDialog";
import {
  WithLayoutMode,
  useLayoutMode,
} from "@/containers/search/LayoutModeContext";
import type {
  ImageCard_imageFragment,
  ImagePreview_imageFragment,
} from "@/gql-types";

import { useDragUrl } from "../Dnd";
import {
  ImagePreviewDialog,
  ImagePreviewDialogDisclosure,
  useImagePreviewDialogStore,
} from "./ImagePreview";
import { ImageSummary } from "./ImageSummary";
import { useImageEditMultiple } from "./image-edit-multiple/ImageEditMultipleContext";

export function ImageCard({
  image,
  imageIds,
}: {
  image: ImageCard_imageFragment;
  imageIds: number[];
}) {
  const imageId = image.id;
  const [, dragRef] = useDragUrl(image.siriusUrl ?? "");
  const { selectedImageIds } = useImageEditMultiple();
  const checked = selectedImageIds.includes(imageId);
  const layoutMode = useLayoutMode();

  return (
    <CardListItem
      ref={dragRef}
      variant={image.expired ? "danger" : undefined}
      layoutMode={layoutMode ?? "grid"}
    >
      <ImageEditMultipleCheckbox
        imageId={imageId}
        className="absolute left-2 top-2 z-30"
        listImageIds={imageIds}
        checked={checked}
      />
      <CardListItemEditDisclosure
        title="Éditer l’image"
        imageId={imageId}
        // @ts-expect-error JS component
        as={RemoteEditImageDialogDisclosure}
      />
      <WithLayoutMode mode="list">
        <IoImage className="shrink-0" />
      </WithLayoutMode>
      <ImagePreview image={image} />
      <ImageSummary image={image} />
    </CardListItem>
  );
}

const ImageEditMultipleCheckbox = memo(function ImageEditMultipleCheckbox({
  imageId,
  listImageIds,
  checked,
  className,
  ...props
}: {
  imageId: number;
  listImageIds: number[];
  checked?: boolean;
  className?: string;
}) {
  const { handleMultiSelect } = useImageEditMultiple();
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleMultiSelect?.({
        listImageIds,
        imageId,
        select: event.currentTarget.checked,
      });
    },
    [imageId, listImageIds, handleMultiSelect],
  );

  if (!handleMultiSelect) return null;

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      className={cn(
        checked ? "opacity-100" : "opacity-0 group-hover:opacity-100",
        className,
      )}
      {...props}
    />
  );
});

function ImagePreview({ image }: { image: ImagePreview_imageFragment }) {
  const layoutMode = useLayoutMode();
  const previewState = useImagePreviewDialogStore({ imageId: image.id });
  return (
    <>
      {/*@ts-expect-error JS component*/}
      <ImagePreviewDialogDisclosure
        title="Prévisualiser l’image"
        {...previewState}
        // @ts-expect-error JS component
        render={<PreviewLink />}
      >
        <PreviewLinkContent>
          <CardListItemThumbnail
            alt="image"
            {...(layoutMode === "list" ? image.fixed : image.fluid)}
          />
        </PreviewLinkContent>
        <PreviewLinkHovering>
          <IoExpand />
        </PreviewLinkHovering>
      </ImagePreviewDialogDisclosure>
      <ImagePreviewDialog {...previewState} />
    </>
  );
}

ImagePreview.fragments = {
  image: gql`
    fragment ImagePreview_image on Image {
      id
      fluid(maxHeight: 160) @include(if: $isGrid) {
        ...Image_fluid
      }
      fixed(width: 72, height: 52) @include(if: $isList) {
        ...Image_fixed
      }
    }
    ${Image.fragments.fluid}
    ${Image.fragments.fixed}
  `,
};

ImageCard.fragments = {
  image: gql`
    fragment ImageCard_image on Image {
      id
      expired
      siriusUrl
      ...ImagePreview_image
      ...ImageSummary_image
    }
    ${Image.fragments.fluid}
    ${ImageSummary.fragments.image}
    ${ImagePreview.fragments.image}
  `,
};
