import { Fragment, forwardRef, memo, useState } from "react";
import { useMenuContext } from "swash/Menu";
import {
  Popover,
  PopoverDisclosure,
  PopoverStore,
  usePopoverStore,
} from "swash/Popover";
import { useEditorContext } from "swash/editor";
import { ToolbarItem } from "swash/editor/components/Toolbar";
import { cn } from "swash/utils/classNames";
import { useEventCallback } from "swash/utils/useEventCallback";

import { Image } from "@/components/Image";

import { Emoji, useEmojiQuery } from "../emoji";
import { CHARACTERS, SpecialCharacter } from "./characters";
import { useFrequentlyUsed } from "./useFrequentlyUsed";

const isEmoji = (item: Emoji | SpecialCharacter): item is Emoji => {
  return "encodedImage" in item;
};

function Char(props: React.ComponentProps<"button">) {
  return (
    <button
      {...props}
      className={cn(
        "flex h-10 w-10 items-center justify-center rounded text-lg focus-visible:bg-grey-bg hover:bg-grey-bg",
        props.className,
      )}
    />
  );
}

const GroupTitle = memo<{
  children: React.ReactNode;
}>(function GroupTitle({ children }) {
  return (
    <h3 className="sticky top-0 bg-white p-2 text-sm uppercase tracking-tight">
      {children}
    </h3>
  );
});

const Group = memo<{
  children: React.ReactNode;
}>(function Group({ children }) {
  return <div className="flex flex-wrap px-1 py-0">{children}</div>;
});

const CharactersGroup = memo<{
  characters: (Emoji | SpecialCharacter)[];
  onSelect: (value: SpecialCharacter | Emoji | undefined) => void;
  onInsert: (value: SpecialCharacter | Emoji) => void;
}>(function CharactersGroup({ characters, onSelect, onInsert }) {
  return (
    <Group>
      {characters.map((character, index) => {
        const handleSelect = () => onSelect(character);
        return (
          <Char
            aria-label={isEmoji(character) ? character.title : character.name}
            key={index}
            type="button"
            onMouseEnter={handleSelect}
            onMouseLeave={() => onSelect(undefined)}
            onFocus={handleSelect}
            onClick={() => onInsert(character)}
            className={cn(isEmoji(character) && "w-auto p-1.5")}
          >
            {isEmoji(character) ? (
              <Image src={character.encodedImage} className="h-5" />
            ) : (
              (character.display ?? character.char)
            )}
          </Char>
        );
      })}
    </Group>
  );
});

function SpecialCharSelector({
  onInsert,
  frequentlyUsed,
}: {
  onInsert: (character: SpecialCharacter | Emoji) => void;
  frequentlyUsed: SpecialCharacter[];
}) {
  const [selected, setSelected] = useState<SpecialCharacter | Emoji>();
  const { data: emojis } = useEmojiQuery();

  return (
    <>
      <div className="flex-1 overflow-auto">
        <Fragment>
          <GroupTitle>Fréquemment utilisés</GroupTitle>
          {frequentlyUsed.length === 0 ? (
            <p className="p-1 text-center text-grey-on-light">
              Aucun caractère utilisé
            </p>
          ) : (
            <CharactersGroup
              characters={frequentlyUsed}
              onSelect={setSelected}
              onInsert={onInsert}
            />
          )}
        </Fragment>
        {emojis && emojis.length > 0 && (
          <Fragment>
            <GroupTitle>Émoticônes personnalisées</GroupTitle>
            <CharactersGroup
              characters={emojis}
              onSelect={setSelected}
              onInsert={onInsert}
            />
          </Fragment>
        )}
        {CHARACTERS.map((group, index) => (
          <Fragment key={index}>
            <GroupTitle>{group.name}</GroupTitle>
            <CharactersGroup
              characters={group.characters}
              onSelect={setSelected}
              onInsert={onInsert}
            />
          </Fragment>
        ))}
      </div>
      <div className="flex h-12 shrink-0 items-center gap-2 border-t border-grey-border p-2">
        {selected && (
          <>
            <div className="flex w-8 shrink-0 items-center justify-center text-3xl">
              {isEmoji(selected) ? (
                <Image src={selected.encodedImage} className="h-5" />
              ) : (
                (selected.display ?? selected.char)
              )}
            </div>
            <div className="flex-1 text-sm">
              {isEmoji(selected) ? (
                <>
                  <p>{selected.title}</p>
                  <p>:{selected.name}:</p>
                </>
              ) : (
                <p>{selected.name}</p>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

const SpecialCharSelectorPopover = ({ store }: { store: PopoverStore }) => {
  const { editor } = useEditorContext();

  const [frequentlyUsed, registerUse] = useFrequentlyUsed();

  const handleInsert = useEventCallback(
    (character: SpecialCharacter | Emoji) => {
      if (isEmoji(character)) {
        editor?.commands.insertContent({
          type: "emoji",
          attrs: { name: character.name },
        });
      } else {
        editor?.commands.insertContent(character.char);
        registerUse(character);
      }
      editor?.commands.focus();
      store.setOpen(false);
    },
  );

  return (
    <Popover
      store={store}
      unmountOnHide
      portal
      aria-label="Insérer un caractère spécial"
      className="flex flex-col"
      style={{
        width: 330,
        height: 330,
      }}
    >
      <SpecialCharSelector
        onInsert={handleInsert}
        frequentlyUsed={frequentlyUsed}
      />
    </Popover>
  );
};

export const SpecialCharControl = forwardRef<
  HTMLButtonElement,
  SpecialCharControlProps
>(({ render }, ref) => {
  const menu = useMenuContext();
  const popover = usePopoverStore();
  const { editor } = useEditorContext();

  const handleClickInnerMenu = useEventCallback(() => {
    if (!menu) return;
    popover.setAnchorElement(menu.getState().disclosureElement);
    setTimeout(() => {
      const wrapper = popover.getState().popoverElement;
      if (!wrapper) return;
      menu?.setBaseElement(wrapper);
    }, 0);
  });

  if (!editor) return null;

  return (
    <>
      <PopoverDisclosure
        ref={ref}
        store={popover}
        render={render}
        onClick={handleClickInnerMenu}
      />
      <SpecialCharSelectorPopover store={popover} />
    </>
  );
});

interface SpecialCharControlProps {
  render: React.ReactElement;
}

export const FrequentlyUsedControl = forwardRef<HTMLButtonElement>((_, ref) => {
  const { editor } = useEditorContext();

  const [[mostFrequentlyUsed], registerUse] = useFrequentlyUsed();

  const handleInsert = useEventCallback(() => {
    if (!mostFrequentlyUsed) return;
    if (isEmoji(mostFrequentlyUsed)) {
      editor?.commands.insertContent({
        type: "emoji",
        attrs: { name: mostFrequentlyUsed.name },
      });
    } else {
      editor?.commands.insertContent(mostFrequentlyUsed.char);
      registerUse(mostFrequentlyUsed);
    }
    editor?.commands.focus();
  });

  if (!editor) return null;
  if (!mostFrequentlyUsed) return null;

  return (
    <>
      <ToolbarItem
        label={`Insérer un « ${mostFrequentlyUsed.name} »`}
        onClick={handleInsert}
        ref={ref}
      >
        {isEmoji(mostFrequentlyUsed) ? (
          <Image src={mostFrequentlyUsed.encodedImage} className="h-5" />
        ) : (
          <div className="size-6">
            {mostFrequentlyUsed.display ?? mostFrequentlyUsed.char}
          </div>
        )}
      </ToolbarItem>
    </>
  );
});
