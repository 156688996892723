import {
  BubbleMenu,
  Editor,
  getMarkRange,
  getTextBetween,
} from "@tiptap/react";
import { truncate } from "lodash";
import { useCallback, useState } from "react";
import { Button } from "swash/Button";
import { IoOpenOutline, IoPencil, IoTrash } from "swash/Icon";
import { Link } from "swash/Link";
import { useEditorContext } from "swash/editor";
import { useEventCallback } from "swash/utils/useEventCallback";

import { LinkEditorMenuForm } from "./LinkEditorMenuForm";
import { LinkData } from "./types";

type ShouldShowFn = NonNullable<
  React.ComponentProps<typeof BubbleMenu>["shouldShow"]
>;

export function LinkEditorMenu() {
  const { editor } = useEditorContext();
  const [editMode, setEditMode] = useState(false);
  const [currentLink, setCurrentLink] = useState<LinkData>(null!);

  const shouldShow = useCallback<ShouldShowFn>(({ editor, view, element }) => {
    if (!editor.isEditable || editor.isDestroyed) return false;

    // When clicking on a element inside the bubble menu the editor "blur" event
    // is called and the bubble menu item is focussed. In this case we should
    // consider the menu as part of the editor and keep showing the menu
    const isChildOfMenu = element.contains(document.activeElement);
    const hasEditorFocus = view.hasFocus() || isChildOfMenu;
    if (!hasEditorFocus) return false;

    const isLink = editor.isActive("link");
    if (isLink) {
      const range = getCurrentLinkRange(editor);
      const text = getTextBetween(editor.state.doc, range);
      const { href, affiliate } = editor.getAttributes("link");
      setEditMode(href === "");
      setCurrentLink({
        url: href,
        affiliate,
        text,
      });
      return true;
    }

    return false;
  }, []);

  const onHide = useEventCallback(() => {
    if (!editor) return;
    removeEmptyLinks(editor);
  });

  const removeLink = useCallback(() => {
    if (!editor) return;
    const range = getCurrentLinkRange(editor);
    editor //
      .chain()
      .focus()
      .setTextSelection(range)
      .unsetLink()
      .run();
  }, [editor]);

  const updateLink = useCallback(
    (target: LinkData) => {
      if (!editor) return;
      editor
        .chain()
        .focus()
        .command(({ tr }) => {
          const range = getCurrentLinkRange(editor);
          if (
            target.url !== currentLink.url ||
            target.affiliate !== currentLink.affiliate
          ) {
            tr.addMark(
              range.from,
              range.to,
              editor.schema.mark("link", {
                href: target.url,
                affiliate: target.affiliate,
              }),
            );
          }
          if (target.text !== currentLink.text) {
            tr.insertText(target.text, range.from, range.to);
          }
          return true;
        })
        .run();
    },
    [editor, currentLink],
  );

  return (
    <BubbleMenu
      editor={editor}
      pluginKey="link-menu"
      shouldShow={shouldShow}
      tippyOptions={{
        onHide,
      }}
    >
      {currentLink && (
        <div className="select-none rounded-md border bg-white p-2 focus:outline-none">
          {editMode ? (
            <LinkEditorMenuForm
              link={currentLink}
              setEditMode={setEditMode}
              updateLink={updateLink}
              removeLink={removeLink}
            />
          ) : (
            <LinkPreview
              link={currentLink}
              setEditMode={setEditMode}
              removeLink={removeLink}
            />
          )}
        </div>
      )}
    </BubbleMenu>
  );
}

function LinkPreview({
  link,
  setEditMode,
  removeLink,
}: {
  link: LinkData;
  setEditMode: (value: boolean) => void;
  removeLink: () => void;
}) {
  const preview = truncate(link.url.replace("huit-anchor-", ""), {
    length: 28,
  });

  return (
    <div className="flex items-center justify-between gap-2">
      <Link
        icon={<IoOpenOutline />}
        className="text-sm"
        href={link.url}
        target="_blank"
      >
        {preview}
      </Link>
      <div className="flex gap-1">
        <Button
          iconOnly
          scale="sm"
          appearance="text"
          aria-label="Éditer le lien"
          onClick={() => setEditMode(true)}
        >
          <IoPencil />
        </Button>
        <Button
          iconOnly
          scale="sm"
          appearance="text"
          variant="danger"
          aria-label="Supprimer le lien"
          onClick={removeLink}
        >
          <IoTrash />
        </Button>
      </div>
    </div>
  );
}

const getCurrentLinkRange = (editor: Editor) => {
  const range = getMarkRange(
    editor.state.selection.$anchor,
    editor.schema.mark("link").type,
  );
  if (!range) throw new Error("Cursor is not in a link");
  return range;
};

const removeEmptyLinks = (editor: Editor) => {
  editor.commands.command(({ tr }) => {
    let hasChanges = false;

    editor.state.doc.descendants((node, pos) => {
      const mark = node.marks?.find((mark) => mark.type.name === "link");
      if (mark && !mark.attrs["href"]) {
        hasChanges = true;
        tr.removeMark(pos, pos + node.nodeSize, mark);
      }
    });

    return hasChanges;
  });
};
