import * as Ariakit from "@ariakit/react";
import { useEditorState } from "@tiptap/react";
import { forwardRef, memo, useCallback } from "react";
import { Select, SelectClear, SelectItem, SelectPopover } from "swash/Select";
import { useEditorContext } from "swash/editor";
import { ToolbarItem } from "swash/editor/components/Toolbar";

import { getAllNodesAttributes } from "@/components/editor/helpers/getAllNodesAttributes";
// eslint-disable-next-line @lemonde/import/no-illegal-import
import { BlockTemplate, useBlockTemplates } from "@/containers/BlockTemplates";

type EditorState = {
  value: string[];
  disabled: boolean;
};

type BlockTemplatesSelectorProps = EditorState & {
  items: BlockTemplate[];
  onChange: (blockTemplate?: string) => void;
};

const BlockTemplatesSelector = memo(
  forwardRef<HTMLButtonElement, BlockTemplatesSelectorProps>(
    ({ items, value, onChange, disabled }, ref) => {
      const { editor } = useEditorContext();
      const renderValue = (value: string[]) => {
        if (value.length === 0) return "Styles";
        if (value.length === 1)
          return items.find((item) => item.name === value[0])?.title;
        return `${value.length} styles`;
      };

      return (
        <Ariakit.SelectProvider
          value={value}
          setValue={(v) => {
            if (v.length === 0) {
              onChange();
            }
          }}
        >
          <ToolbarItem
            ref={ref}
            className="max-w-32 hover:border-transparent"
            render={<Select scale="sm" />}
            disabled={disabled}
          >
            <span className="truncate">{renderValue(value)}</span>
            <SelectClear variant="secondary" />
          </ToolbarItem>
          <SelectPopover fitViewport aria-label="Styles">
            {items.map((item) => (
              <SelectItem
                key={item.name}
                value={item.name}
                onClick={() => onChange(item.name)}
                disabled={!editor?.can().setBlockTemplate(item.name)}
              >
                {item.title}
              </SelectItem>
            ))}
          </SelectPopover>
        </Ariakit.SelectProvider>
      );
    },
  ),
);

export const BlockTemplatesControl = forwardRef<HTMLButtonElement>((_, ref) => {
  const { editor } = useEditorContext();

  const blockTemplates = useBlockTemplates();

  const handleChange = useCallback(
    (blockTemplate?: string) =>
      blockTemplate
        ? editor?.chain().focus().setBlockTemplate(blockTemplate).run()
        : editor?.chain().focus().unsetBlockTemplate().run(),
    [editor],
  );

  const editorState: EditorState | null = useEditorState({
    editor,
    selector: ({ editor }) => {
      if (!editor) return null;

      const styleNames = editor.storage.blockTemplate.types.flatMap(
        (type: string) =>
          getAllNodesAttributes(editor.state, type)
            .map((attr) => attr.styleName)
            .filter(Boolean),
      );

      return {
        disabled: !editor.can().setBlockTemplate(),
        value: styleNames,
      };
    },
  });

  if (!editorState) return null;

  return (
    <BlockTemplatesSelector
      ref={ref}
      items={blockTemplates}
      onChange={handleChange}
      {...editorState}
    />
  );
});
