import { Node } from "@tiptap/core";
import { Bold } from "@tiptap/extension-bold";
import { Heading } from "@tiptap/extension-heading";
import { HorizontalRule } from "@tiptap/extension-horizontal-rule";
import { Italic } from "@tiptap/extension-italic";
import { Underline } from "@tiptap/extension-underline";

import { BlockTemplate } from "../extensions/block-template";
import { InvisibleCharactersExtension } from "../extensions/invisible-characters";
import { RemoveStyleExtension } from "../extensions/remove-style";
import { SpecialCharactersExtension } from "../extensions/special-characters";
import { ToggleCaseExtension } from "../extensions/toggle-case";
import { TypographicRulesExtension } from "../extensions/typographic-rules";
import { Link } from "../marks/link";
import { Strike } from "../marks/strike";
import { Subscript } from "../marks/subscript";
import { Superscript } from "../marks/superscript";
import { Blockquote } from "../nodes/blockquote";
import { BulletList } from "../nodes/bullet-list";
import { Chapo } from "../nodes/chapo";
import { EmojiNode } from "../nodes/emoji";
import { ListItem } from "../nodes/list-item";
import { OrderedList } from "../nodes/ordered-list";
import { Title } from "../nodes/title";
import { Core, KitOptions, createKit } from "./base";

const ArticleDocument = Node.create<never>({
  name: "doc",
  topNode: true,
  content: "title chapo block+",
});

export const ArticleKit = createKit("articleKit", () => ({
  ...Core,

  // Nodes
  blockquote: Blockquote,
  blockTemplate: BlockTemplate,
  bulletList: BulletList,
  chapo: Chapo,
  document: ArticleDocument,
  emoji: EmojiNode,
  heading: Heading.configure({ levels: [2, 3] }),
  horizontalRule: HorizontalRule,
  listItem: ListItem,
  orderedList: OrderedList,
  title: Title,

  // Marks
  bold: Bold,
  italic: Italic,
  link: Link,
  strike: Strike,
  subscript: Subscript,
  superscript: Superscript,
  underline: Underline,

  // Functionality
  invisibleCharacters: InvisibleCharactersExtension,
  removeStyle: RemoveStyleExtension,
  specialCharacters: SpecialCharactersExtension,
  toggleCase: ToggleCaseExtension,
  typographicRules: TypographicRulesExtension,
}));

export type ArticleKitOptions = KitOptions<typeof ArticleKit>;
