import { Node } from "@tiptap/core";
import { Document } from "@tiptap/extension-document";

import { InvisibleCharactersExtension } from "../extensions/invisible-characters";
import { SpecialCharactersExtension } from "../extensions/special-characters";
import { ToggleCaseExtension } from "../extensions/toggle-case";
import { TypographicRulesExtension } from "../extensions/typographic-rules";
import { EmojiNode } from "../nodes/emoji";
import { Core, KitOptions, createKit } from "./base";

const SingleLineDocument = Node.create<never>({
  name: "doc",
  topNode: true,
  // Cannot use `inline*` because the document requires at least one descendant to display the placeholder.
  content: "paragraph",
});

export const SimpleTextKit = createKit(
  "simpleTextKit",
  (options: { multiline?: boolean } = {}) => ({
    ...Core,

    // Nodes
    document: options.multiline ? Document : SingleLineDocument,
    emoji: EmojiNode,

    // Functionality
    invisibleCharacters: InvisibleCharactersExtension,
    specialCharacters: SpecialCharactersExtension,
    toggleCase: ToggleCaseExtension,
    typographicRules: TypographicRulesExtension.configure({
      italicsBetweenQuotes: false,
    }),
  }),
);

export type SimpleTextKitOptions = KitOptions<typeof SimpleTextKit>;
