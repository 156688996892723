import { memo } from "react";

import { SimpleInfiniteScroll } from "@/components/InfiniteScroll";
import { SearchFormList } from "@/containers/search/SearchForm";

import { PostListSet as Set } from "./PostListSet";
import { PostRow, columns } from "./PostRow";

const setProviderOptions = { columns };

export const PostListProvider = ({ posts, hasMore, loadMore, loading }) => {
  return (
    <Set.Provider options={setProviderOptions}>
      <Set.List display="block">
        {posts.map((post) => (
          <PostRow key={post.id} post={post} />
        ))}
      </Set.List>
      <SimpleInfiniteScroll
        hasMore={hasMore}
        loadMore={loadMore}
        loading={loading}
      />
    </Set.Provider>
  );
};

export const PostList = memo(({ data, loadMore, loading }) => {
  return (
    <Set.Provider options={setProviderOptions}>
      <Set.List className="border-spacing-y-4">
        <SearchFormList data={data} loadMore={loadMore} loading={loading}>
          {(data) =>
            data.connection.nodes.map((post) => (
              <PostRow key={post.id} post={post} />
            ))
          }
        </SearchFormList>
      </Set.List>
    </Set.Provider>
  );
});
