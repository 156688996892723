import * as Ariakit from "@ariakit/react";
import { useStoreState } from "@ariakit/react";
import { Editor, useEditorState } from "@tiptap/react";
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { mergeRefs } from "react-merge-refs";

import { IoEllipsisVertical } from "../../Icon";
import {
  Popover,
  PopoverDisclosure,
  PopoverProvider,
  usePopoverContext,
} from "../../Popover";
import { Tooltip } from "../../Tooltip";
import { cn } from "../../utils/classNames";
import { warning } from "../../utils/warning";
import { useEditorContext } from "../index";

export const Toolbar = forwardRef<HTMLDivElement, EditorToolbarProps>(
  ({ editor, children: propChildren, ...props }, forwardedRef) => {
    const context = useEditorContext();
    editor = editor || context.editor;

    const [overflowIndex, setOverflowIndex] = useState(
      Children.toArray(propChildren).length,
    );
    const containerRef = useRef<HTMLDivElement>(null);
    const editable = useEditorState({
      editor,
      selector: ({ editor }) => editor?.isEditable ?? false,
    });

    const refs = useRef({
      contentWidth: 0,
      gap: 0,
      childWidths: [] as number[],
      overflowButtonWidth: 0,
    });

    const children = useMemo(() => {
      const { childWidths } = refs.current;
      // convert children to array to filter out null and undefined children
      return Children.toArray(propChildren)
        .map((child, index) => {
          warning(
            !isValidElement(child),
            "Toolbar children must be valid elements",
          );
          if (!isValidElement(child)) return;
          return cloneElement(child, {
            ref: (elt: HTMLElement) => {
              if (
                elt &&
                elt.offsetWidth &&
                childWidths[index] !== elt.offsetWidth
              ) {
                childWidths.push(elt.offsetWidth);
              }
            },
            key: index,
          } as any);
        })
        .filter(Boolean);
    }, [propChildren]);

    const { standardChildren, overflowChildren } = useMemo(
      () => ({
        standardChildren: children.slice(0, overflowIndex),
        overflowChildren: children.slice(overflowIndex),
      }),
      [children, overflowIndex],
    );

    const hasOverflow = overflowChildren.length > 0;
    const prevOverflowIndex = useRef(overflowIndex);
    prevOverflowIndex.current = overflowIndex;

    const distributeItems = useCallback((overflowSpace: number) => {
      const { childWidths, gap } = refs.current;
      let index = childWidths.length - 1;

      while (overflowSpace > 0 && index >= 0) {
        const width = childWidths[index--];
        if (!width) break;
        overflowSpace -= width + gap;
      }

      // if last bar item is a separator, force it to overflow even if there is enough space for it
      if (index > 0) {
        let lastItem = children[index];
        while (
          lastItem &&
          isValidElement(lastItem) &&
          lastItem.type === ToolbarSeparator
        ) {
          index--;
          lastItem = children[index];
        }
      }

      const newOverflowIndex = index + 1;

      if (prevOverflowIndex.current !== newOverflowIndex) {
        setOverflowIndex(newOverflowIndex);
      }
    }, []);

    const processOverflow = useCallback(
      (containerWidth: number) => {
        const { overflowButtonWidth = 32, contentWidth } = refs.current;
        if (!containerWidth) return;
        const overflowSpace = Math.max(
          0,
          contentWidth - containerWidth + overflowButtonWidth,
        );

        distributeItems(overflowSpace);
      },
      [distributeItems],
    );

    useLayoutEffect(() => {
      if (!containerRef.current || !editable) return;
      const { childWidths } = refs.current;
      const gap = parseFloat(getComputedStyle(containerRef.current).gap || "0");
      refs.current.gap = gap;
      refs.current.contentWidth = childWidths.reduce(
        (sum, w) => sum + w + gap,
        0,
      );
      const padding = parseFloat(
        getComputedStyle(containerRef.current).padding || "0",
      );
      processOverflow(containerRef.current.offsetWidth - padding);
    }, [editable, processOverflow]);

    useEffect(() => {
      if (!containerRef.current) return;
      const resizeObserver = new ResizeObserver(([entry]) => {
        if (!entry) return;
        processOverflow(entry.contentRect.width);
      });
      resizeObserver.observe(containerRef.current);
      return () => resizeObserver.disconnect();
    }, [processOverflow]);

    if (!editable) return null;

    return (
      <Ariakit.Toolbar
        ref={mergeRefs([forwardedRef, containerRef])}
        {...props}
        className={cn("toolbar", props.className)}
      >
        {standardChildren}
        <PopoverProvider>
          <OverflowButton
            ref={(elt: HTMLButtonElement) => {
              if (!elt) return;
              refs.current.overflowButtonWidth = elt.offsetWidth;
            }}
            hide={!hasOverflow}
          />
          {hasOverflow && (
            <Popover>
              <Ariakit.Toolbar className="toolbar overflow-toolbar">
                {overflowChildren}
              </Ariakit.Toolbar>
            </Popover>
          )}
        </PopoverProvider>
      </Ariakit.Toolbar>
    );
  },
);

const OverflowButton = forwardRef<HTMLButtonElement, OverflowButtonProps>(
  ({ hide, ...props }, ref) => {
    const popover = usePopoverContext();
    const open = useStoreState(popover, "open");

    useEffect(() => {
      if (!popover || !hide) return;
      popover.hide();
      return () => {
        popover.hide();
      };
    }, [hide, popover]);

    return (
      <PopoverDisclosure
        ref={ref}
        render={<ToolbarItem active={open} label="Plus d’outils" />}
        className={cn("overflow-button", hide && "invisible")}
        {...props}
      >
        <IoEllipsisVertical />
      </PopoverDisclosure>
    );
  },
);

interface OverflowButtonProps extends Ariakit.PopoverDisclosureProps {
  hide: boolean;
}

export interface EditorToolbarProps extends Ariakit.ToolbarProps {
  editor?: Editor | null;
}

export const ToolbarItem = forwardRef<HTMLButtonElement, ToolbarItemProps>(
  ({ active, label, shortcut, ...props }, ref) => {
    const tooltip = useMemo(() => {
      return (label || shortcut) && !props.disabled ? (
        <div className="flex flex-col items-center gap-1">
          {label && <div>{label}</div>}
          {shortcut && (
            <div className="mb-1 text-xs text-grey-300">{shortcut}</div>
          )}
        </div>
      ) : null;
    }, [label, shortcut, props.disabled]);
    return (
      <Tooltip tooltip={tooltip} placement="bottom">
        <Ariakit.ToolbarItem
          ref={ref}
          data-active={active ? "" : undefined}
          accessibleWhenDisabled
          {...props}
          className={cn(
            "toolbar-item p-0.5 data-[active]:bg-dusk-on hover:ring-dusk-border-hover",
            props.className,
          )}
        >
          <Ariakit.VisuallyHidden>{label}</Ariakit.VisuallyHidden>
          {props.children}
        </Ariakit.ToolbarItem>
      </Tooltip>
    );
  },
);

export interface ToolbarItemProps extends Ariakit.ToolbarItemProps {
  active?: boolean;
  label?: string;
  shortcut?: string;
}

export const ToolbarSeparator = forwardRef<
  HTMLHRElement,
  ToolbarSeparatorProps
>((props, ref) => (
  <Ariakit.ToolbarSeparator
    ref={ref}
    {...props}
    className={cn("separator", props.className)}
  />
));

export interface ToolbarSeparatorProps extends Ariakit.ToolbarSeparatorProps {}
